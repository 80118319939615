import { extendTheme, baseTheme } from "@chakra-ui/react";

const theme = {
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fonts: {
    heading: "Kanit, sans-serif",
    body: "Montserrat, sans-serif",
  },
  colors: {
    black: "#131313",
    gray: {
      100: "#bcbcbc",
      200: "#a2a2a2",
      300: "#898989",
      400: "#6f6f6f",
      500: "#565656",
      600: "#3c3c3c",
      700: "#232323",
      800: "#090909",
      900: "#000000",
    },
    brand: {
      100: "#ffdc72",
      200: "#ffc258",
      300: "#ffa93f",
      400: "#ff8f25",
      500: "#e6760c",
      600: "#cc5c00",
      700: "#b34300",
      800: "#992900",
      900: "#801000",
    },
  },
  styles: {
    global: (props) => ({
      html: {
        backgroundColor: "black",
      },
    }),
  },
};

export default extendTheme(theme);
export { baseTheme };
